import * as React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { Container } from "./ui/grid"
import TextGradient from "./ui/text-gradient"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

const Faq = ({ data, title }) => {
  return (
    <section id="faq" className="max-w-4xl mb-32 mx-auto">
      <Container>
        <h2 className="lg:mb-12 text-center">
          <TextGradient>{title}</TextGradient>
        </h2>

        <Accordion allowZeroExpanded allowMultipleExpanded>
          {data &&
            data.map(faq => (
              <AccordionItem
                key={faq?.id}
                className="relative mb-4 py-2 pl-6 border border-gray-100 shadow rounded">
                <AccordionItemHeading className="font-bold lg:text-xl">
                  <AccordionItemButton className="accordion-button relative py-3 pr-20 outline-none">
                    {faq?.question}
                  </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel>
                  <div className="py-6 prose max-w-none pr-6">
                    {faq?.answer && renderRichText(faq?.answer)}
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
        </Accordion>
      </Container>
    </section>
  )
}

export default Faq
