import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row } from "../components/ui/grid"
import { TableHeader, TableTitle, TableRow } from "../components/pricing-table"
import HeroPricing from "../components/hero-pricing"
import Enterprise from "../components/enterprise"
import Faq from "../components/faq"

const Pricing = ({ location }) => {
  const { data, faq } = useStaticQuery(
    graphql`
      query {
        data: contentfulPagePricing {
          title
          description
          featuredImage {
            gatsbyImageData
            resize(width: 512, quality: 80) {
              src
            }
          }
          faqTitle
          comparisonTableTitle
          comparisonTable {
            title
            id
            table {
              ... on ContentfulPlanComparisonTableItem {
                id
                title
                free
                team
                business
                enterprise
              }
              ... on ContentfulPlanComparisonTableCustomItem {
                id
                title
                freeCustom: free
                teamCustom: team
                businessCustom: business
                enterpriseCustom: enterprise
              }
            }
          }
        }
        faq: allContentfulFaq {
          nodes {
            id
            question
            answer {
              raw
            }
          }
        }
      }
    `
  )

  const title = data?.title || ""
  const description = data?.description || ""
  const thumbnail = data?.featuredImage?.resize?.src || ""
  const comparisonTitle = data?.comparisonTableTitle || ""
  const faqTitle = data?.faqTitle || ""
  const comparisonTable = data?.comparisonTable || []

  return (
    <Layout
      location={location}
      hero={<HeroPricing />}
      beforeFooter={<Enterprise />}>
      <Seo
        title={title}
        description={description}
        image={thumbnail}
        url={location.href}
      />

      <div id="plans" className="my-12 md:my-32">
        <Container>
          <h2 className="mb-16 text-center">{comparisonTitle}</h2>

          <Row>
            <TableHeader />
            {comparisonTable &&
              comparisonTable.map(table => (
                <div key={table?.id} className="w-full">
                  <TableTitle title={table?.title} />

                  {table?.table.map(item => (
                    <TableRow
                      key={item?.id}
                      name={item?.title}
                      community={item?.freeCustom || item?.free}
                      basic={item?.teamCustom || item?.team}
                      pro={item?.businessCustom || item?.business}
                      enterprise={item?.enterpriseCustom || item?.enterprise}
                    />
                  ))}
                </div>
              ))}
          </Row>
        </Container>
      </div>

      <Faq data={faq?.nodes} title={faqTitle} />
    </Layout>
  )
}

export default Pricing
