import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { Container, Row, Col } from "./ui/grid"
import ShapeDown from "./ui/shape-down"
import CardPlan from "./ui/card-plan"
import TextGradient from "./ui/text-gradient"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className="text-white max-w-2xl mx-auto">{children}</h1>
    },
  },
}

const HeroPricing = () => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPagePricing {
          heroContent {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
          plans {
            id
            features
            name
            priceLabel
            prices
            monthlyApiTestRuns
            buttonLabel
            buttonLink
          }
          comparisonTableTitle
        }
      }
    `
  )

  const content = data?.heroContent || ""
  const subtitle = data?.comparisonTableTitle || ""
  const plans = data?.plans || []

  return (
    <ShapeDown>
      <Container>
        <div className="max-w-4xl mx-auto text-center">
          {content && renderRichText(content, options)}

          <AnchorLink
            to="/pricing#plans"
            title={subtitle}
            className="inline-block mx-auto mt-6 text-xl text-gray-light text-center underline">
            {subtitle}
          </AnchorLink>
        </div>

        <div className="relative z-10 mt-12 -mb-24 md:mt-24 md:-mb-20 lg:-mb-32 xl:-mb-48 2xl:-mb-64">
          <Row>
            {plans &&
              plans.map((plan, index) => (
                <Col key={plan.id} size="w-full lg:w-1/4" className="mb-6">
                  <CardPlan
                    title={plan.name}
                    prices={plan.prices}
                    apiRuns={plan.monthlyApiTestRuns}
                    features={plan.features}
                    priceLabel={plan.priceLabel}
                    btnLabel={plan.buttonLabel}
                    linkTo={plan.buttonLink}
                    index={index}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    </ShapeDown>
  )
}

export default HeroPricing
