import * as React from "react"
import classNames from "classnames"

import ListItemWithIcon from "../ui/list-item-with-icon"
import PlusIcon from "../../svg/plus.svg"
import Button from "./button"
import PriceComponent from "./price-component"

const CardPlan = ({
  title,
  prices,
  apiRuns,
  features,
  priceLabel,
  btnLabel,
  btnStyle,
  linkTo,
  index,
}) => {
  const classes = classNames({
    "flex flex-col w-full h-full bg-white rounded-xl bg-top-decor bg-no-repeat overflow-hidden shadow-lg": true,
    "bg-gradient-to-l from-blue to-blue": index === 0,
    "bg-gradient-to-r from-blue to-blue": index === 1,
    "bg-gradient-to-r from-blue to-blue-pacific": index === 2,
    "bg-gradient-to-r from-blue-pacific to-blue-pacific": index === 3,
  })

  const titleClasses = classNames({
    "text-xl": true,
    "text-white": index === 0,
    " text-blue": index === 1,
    "text-blue": index === 2,
    "text-blue-pacific": index === 3,
  })

  const priceClasses = classNames({
    "mb-8 font-bold text-4xl text-center": true,
    "text-green": index === 0,
    "text-blue": index === 1,
    " text-blue": index === 2,
    "text-blue-pacific": index === 3,
  })

  const iconClasses = classNames({
    "list-icon transform translate-y-1": true,
    "text-green": index === 0,
    "text-blue": index === 1,
    " text-blue": index === 2,
    "text-blue-pacific": index === 3,
  })

  const getBtnStyle = index => {
    switch (index) {
      case 0:
        return "green"

      case 3:
        return "blue-pacific"

      default:
        return "blue"
    }
  }

  return (
    <div className={classes}>
      <div className="px-6 p-6 text-center 2xl:px-6 2xl:pt-12">
        <h2 className={titleClasses}>{title}</h2>
      </div>

      <div className="px-6 pb-4 2xl:px-6">
        {prices && apiRuns ? (
          <PriceComponent prices={prices} apiRuns={apiRuns} />
        ) : (
          <div className={priceClasses}>{priceLabel}</div>
        )}
      </div>

      <div className="px-6 pb-6 2xl:px-6 2xl:pb-12">
        <ul className="list-none">
          {features &&
            features.map(item => (
              <ListItemWithIcon
                key={item}
                text={item}
                icon={<PlusIcon className={iconClasses} />}
                className="relative mb-2 pl-5 lg:text-xs xl:text-sm"
              />
            ))}
        </ul>
      </div>

      <div className="px-6 pb-6 mt-auto text-center 2xl:px-6 2xl:pb-12">
        <Button
          label={btnLabel}
          color={getBtnStyle(index)}
          to={linkTo}
          className="!px-0.5 w-full lg:text-sm xl:text-base"
        />
      </div>
    </div>
  )
}

CardPlan.defaultProps = {
  btnLabel: "Get Started",
  btnStyle: "blue",
  linkTo: "/",
}

export default CardPlan
