import React from "react"
import classNames from "classnames"
import LogoEl1 from "../../svg/logo-el-1.svg"

import Shape from "../../svg/shape-down.svg"

const ShapeDown = ({ x, y, color, className, children }) => {
  const mainClasses = classNames({
    "relative bg-gradient-to-t from-blue-royal via-black to-black pt-12 md:pt-32 pb-[calc(calc(calc(100vw-0px)*0.05)+6rem)] sm:pb-[calc(calc(calc(100vw-0px)*0.05)+10rem)] lg:pb-[calc(calc(calc(100vw-0px)*0.05)+12rem)]": true,
    [className]: className,
  })

  const shapeClasses = classNames({
    "absolute left-0 -bottom-px w-full h-auto": true,
    "transform -scale-x-1": x === "left",
    "text-white": !color,
    [color]: color,
  })

  return (
    <div className={mainClasses}>
      <div className="relative z-10">{children}</div>

      <div className="absolute left-0 top-0 w-full h-full overflow-hidden">
        <div className="absolute bg-gradient-to-r from-blue-royal to-transparent-dark opacity-20 bottom-[-52rem] left-[-10rem] w-[128rem] h-[90rem] rotate-[-33deg] xl:left-[-5rem] 2xl:left-[10rem]"></div>
        <div className="absolute bg-gradient-to-r from-blue-royal to-transparent-dark opacity-20 bottom-[-55rem] left-[-10rem] w-[128rem] h-[90rem] rotate-[-33deg] xl:left-[-5rem] 2xl:left-[10rem]"></div>
        <div className="absolute bg-gradient-to-r from-blue-lucky via-transparent-dark to-transparent-dark opacity-30 bottom-[-65rem] left-[-10rem] w-[90rem] h-[90rem] rotate-[-33deg] xl:left-[-5rem] 2xl:left-[100rem]"></div>
      </div>

      <Shape className={shapeClasses} />

      <LogoEl1
        className={`absolute h-auto w-1/5 -bottom-12 sm:-bottom-20 md:w-1/6 lg:-bottom-20 lg:w-44 xl:-bottom-28 xl:w-[15%] ${
          x === "left" ? "left-0" : "right-0"
        }`}
      />
    </div>
  )
}

export default ShapeDown
