import * as React from "react"
// import ReactSlider from "react-slider"
import { priceFormat } from "../../helpers"

const PriceSlider = ({ prices }) => {
  const [slider] = React.useState(0)

  return (
    <React.Fragment>
      <div
        aria-label={`$${prices[slider]} per month`}
        className="mb-8 text-center">
        <span className="inline-block mr-1.5 text-lg text-gray-dim transform -translate-y-4">
          $
        </span>

        <span className="font-bold text-5xl">
          {priceFormat(prices[slider])}
        </span>

        <span className="inline-block text-lg text-gray-dim">/mo</span>
      </div>

      {/* Ido: removing dynamic price slider */}
      {/* <div className="flex flex-wrap items-center mb-4">
        <div className="w-full mb-2 font-bold text-sm">
          API Test runs/mo {apiRuns[slider]}
        </div>

        <ReactSlider
          min={0}
          max={apiRuns.length - 1}
          defaultValue={0}
          className="horizontal-slider"
          thumbClassName="lm-slider-thumb"
          trackClassName="lm-slider-track"
          onChange={curVal => setslider(curVal)}
          renderThumb={props => <div {...props}></div>}
        />
      </div> */}
    </React.Fragment>
  )
}

PriceSlider.defaultProps = {
  prices: [],
  apiRuns: [],
}

export default PriceSlider
