import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import { Container, Row, Col } from "./ui/grid"
import TextGradient from "./ui/text-gradient"
import ListItemWithIcon from "./ui/list-item-with-icon"
import CheckedIcon from "../svg/check.svg"
import Button from "./ui/button"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className="pr-12 text-5xl text-white">{children}</h2>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className="mt-6 text-lg text-white font-medium">{children}</p>
    },
  },
}

const Enterprise = () => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulCtaEnterprise {
          content {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
          checkListTitle
          checkList
          button {
            title
            url
          }
        }
      }
    `
  )

  const content = data?.content || null
  const checkListTitle = data?.checkListTitle || ""
  const checkList = data?.checkList || []
  const button = data?.button || null

  return (
    <div className="py-24 md:py-32">
      <Container>
        <Row alignX="center">
          <Col size="w-full md:w-2/5">
            <div>{content && renderRichText(content, options)}</div>
          </Col>

          <Col size="w-full md:w-3/5">
            <div>
              <p className="text-lg text-white font-medium">{checkListTitle}</p>

              <ul className="mt-8 mb-12 list-none relative mx-auto text-left text-white">
                {checkList &&
                  checkList.map(item => (
                    <ListItemWithIcon
                      key={item}
                      text={item}
                      className="pl-9"
                      icon={<CheckedIcon className="list-icon h-6 w-6" />}
                    />
                  ))}
              </ul>

              <Button label={button?.title} to={button?.url} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Enterprise
