import * as React from "react"
import clasNames from "classnames"

const ListItemWithIcon = ({ text, icon, className }) => {
  const classes = clasNames({
    "lm-list-item pl-9 relative mb-4": true,
    [className]: className,
  })

  return (
    <li className={classes}>
      {icon}
      {text}
    </li>
  )
}

export default ListItemWithIcon
