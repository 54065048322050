import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import CheckedIcon from "../svg/check.svg"
import Button from "./ui/button"

const checkHandler = val => {
  let cellVal
  switch (val) {
    case true:
      cellVal = <CheckedIcon className="w-6 h-6" />
      break

    case false:
      cellVal = <span className="text-gray-400">—</span>
      break

    default:
      cellVal = val
      break
  }

  return cellVal
}

export function TableHeader() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            demo
            accessRequest
          }
        }
      }
    `
  )

  return (
    <div className="lm-table-header sticky top-offset-sticky flex flex-wrap bg-white">
      <div className="w-full lg:w-2/5 flex justify-end flex-col font-bold"></div>
      <div className="w-full lg:w-3/5">
        <div className="flex flex-wrap">
          <div className="w-1/4 flex py-4 px-4 justify-center font-bold border-r border-gray-200">
            <div className="text-center">
              <h3 className="mb-3 text-sm text-green-550 lg:text-base">Free Trial</h3>

              <Button
                label="Request Access"
                color="green"
                size="sm"
                to={site.siteMetadata.accessRequest}
                className="text-xs px-2 md:text-sm md:px-4"
              />
            </div>
          </div>

          <div className="w-1/4 flex py-4 px-4 justify-center font-bold border-r border-gray-200">
            <div className="text-center">
              <h3 className="mb-3 text-sm text-blue lg:text-base">Starter</h3>

              <Button
                label="Get Started"
                color="blue"
                size="sm"
                to={site.siteMetadata.demo}
                className="text-xs px-2 md:text-sm md:px-4"
              />
            </div>
          </div>

          <div className="w-1/4 flex py-4 px-4 justify-center font-bold border-r border-gray-200">
            <div className="text-center">
              <h3 className="mb-3 text-sm text-blue lg:text-base">Business</h3>

              <Button
                label="Buy Now"
                color="blue"
                size="sm"
                to={site.siteMetadata.demo}
                className="text-xs px-2 md:text-sm md:px-4"
              />
            </div>
          </div>

          <div className="w-1/4 flex py-4 px-4 justify-center font-bold">
            <div className="text-center">
              <h3 className="mb-3 text-sm text-blue-pacific lg:text-base">
                Enterprise
              </h3>

              <Button
                label="Contact Us"
                color="blue-pacific"
                size="sm"
                to={site.siteMetadata.demo}
                className="text-xs px-2 md:text-sm md:px-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function TableTitle({ title }) {
  return (
    <div className="flex flex-wrap w-full mt-4 lg:mt-0">
      <div className="w-full lg:w-2/5 flex justify-end flex-col py-5 font-bold lg:py-0">
        <h3 className="mb-0 p-4 lg:pl-0 text-2xl font-bold lg:ml-12 lg:text-4xl">
          {title}
        </h3>
      </div>
      <div className="w-full lg:w-3/5 text-center">
        <div className="flex flex-wrap">
          <div className="w-1/4 flex lg:h-32 lg:border-r lg:border-gray-200"></div>
          <div className="w-1/4 flex lg:h-32 lg:border-r lg:border-gray-200"></div>
          <div className="w-1/4 flex lg:h-32 lg:border-r lg:border-gray-200"></div>
          <div className="w-1/4 flex lg:h-32"></div>
        </div>
      </div>
    </div>
  )
}

export function TableRow({ name, community, basic, pro, enterprise }) {
  return (
    <div className="lm-table-row flex flex-wrap">
      <div className="w-full lg:w-2/5 flex justify-center flex-col font-bold lg:py-0">
        <span className="p-4 text-lg font-medium bg-gray-light lg:py-2 lg:pl-0 lg:bg-transparent lg:ml-12 lg:bg-light">
          {name}
        </span>
      </div>
      <div className="w-full lg:w-3/5 text-center">
        <div className="flex flex-wrap">
          <div className="w-1/4 flex py-4 items-center justify-center text-sm border-r border-gray-200 lg:text-base">
            {checkHandler(community)}
          </div>
          <div className="w-1/4 flex py-4 items-center justify-center text-sm border-r border-gray-200 lg:text-base">
            {checkHandler(basic)}
          </div>
          <div className="w-1/4 flex py-4 items-center justify-center text-sm border-r border-gray-200 lg:text-base">
            {checkHandler(pro)}
          </div>
          <div className="w-1/4 flex py-4 items-center justify-center text-sm lg:text-base">
            {checkHandler(enterprise)}
          </div>
        </div>
      </div>
    </div>
  )
}
